@import '../../styles/mixins.scss';
@import '../../styles/colours.scss';

.infoLineContainer {
	@include flexRow;
	width: 100%;
	// height: 16px;
	// padding: 8px;
	// background: $brp-yellow;
}

.left {
	// background: $brp-black;
	// clip-path: polygon(0 0, 100% 0%, 75% 100%, 0% 100%);
	// padding: 4px 8px 4px 64px;
	// flex: 4;
	color: $brp-grey;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
}

.right {
	flex: 1;
}

.icon {
	padding: 8px;
	background: $brp-black;
	color: $brp-white;
	transition: 300ms;
	&:not(:last-child) {
		margin-right: 8px;
	}
}

#jetpowerHeader {
	color: $brp-black;
	background-color: $brp-white;
}

#jetpowerInfoline {
	// background-color: $brp-black;
	background: rgb(255, 210, 0);
	background: linear-gradient(
		300deg,
		rgba(255, 210, 0, 1) 0%,
		rgba(255, 210, 0, 1) 65%,
		rgba(51, 49, 50, 1) 65%,
		rgba(51, 49, 50, 1) 100%
	);
	color: $brp-silver;
	font-weight: 300;
	// background-color: $brp-yellow;
}

.navigationItem {
	color: $brp-black;
	text-decoration: none;
	font-weight: bold;
	font-size: 1.15em;
	&:not(:last-child) {
		margin-right: 24px;
	}
	text-transform: capitalize;
}

.rightSide {
	background-color: #ffd200;
	text-align: right;
	clip-path: polygon(5% 0, 100% 0%, 100% 100%, 0 100%);
	// clip-path: polygon(0 0, 100% 0%, 75% 100%, 0% 100%);
}

.itemCounterDot {
	width: 16px;
	height: 16px;
	background-color: $brp-yellow;
	border-radius: 50%;
	position: absolute;
	top: -8px;
	right: 0px;
	color: $brp-black;
	text-align: center;
	line-height: 18px;
	font-size: 12px;
}
