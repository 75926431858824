@import '../../styles/colours.scss';
.subscriptionContainer {
	// position: absolute;
	// top: 0;
	// left: 0;
	// right: 0;
	// bottom: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: auto 0;
	align-items: center;
	// padding-left: 30%;
	// padding-bottom: 0%;
	text-align: left;
}

.subscriptionTextContainer {
	//
	// width: max-content;
	/* margin: auto; */
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.inputBackground {
	background-color: $brp-grey;
	.MuiInput-underline:before,
	.MuiInput-underline:after {
		border-bottom-color: $canam-on-red !important;
	}
}

.seadooClip {
	clip-path: url('./seadoo-wave.svg');
}
