// .headingContainer {
// 	height: 100px;
// 	position: relative;
// 	padding-left: 32px;
// }

.subHeading {
	padding-top: 16px;
	margin-bottom: 0em;
	padding-left: 16px;
	text-transform: uppercase;
	// position: absolute;
}

.subTitle {
	padding-left: 80px;
	padding-top: 16px;
	margin-top: 0;
}

.verticalLine {
	width: 64px;
	height: 192px;
	position: absolute;
	z-index: -1;
	// margin-left: 10px;
	// margin-top: -100%;
}
