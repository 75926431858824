.linkItem {
	width: max-content;
	padding-top: 4px;
	padding-bottom: 4px;
	cursor: pointer;
}

.yellowToGrey {
	height: 2px;
	margin-bottom: 32px;
	width: 100%;
	background: linear-gradient(
		90deg,
		rgba(255, 210, 0, 1) 0%,
		rgba(255, 210, 0, 1) 10%,
		rgba(240, 240, 240, 1) 10%
	);
}
