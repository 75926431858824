@mixin flexRow{
    display: flex;
    flex-direction: row;
}
@mixin flexCol{
    display: flex;
    flex-direction: column;
}
@mixin centrePointFlex{
    justify-content: center;
    align-items: center;
}