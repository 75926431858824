// jetpower

// sea-doo
// font: navigo

// ski-doo
// font: t-star pro

// can-am on-road
// font: industry
// secondary: neue haas unica w1g

// can-am off-road
// font: industry
// secondary: neue haas unica w1g

// brp-world
// primary-font: TRADE GOTHIC LT PRO
@font-face {
	font-family: 'Neue Haas Unica W1G';
	src: url('../assets/fonts/NeueHaasUnicaW1G-Bold.eot');
	src: url('../assets/fonts/NeueHaasUnicaW1G-Bold.eot?#iefix')
			format('embedded-opentype'),
		url('../assets/fonts/NeueHaasUnicaW1G-Bold.woff2') format('woff2'),
		url('../assets/fonts/NeueHaasUnicaW1G-Bold.woff') format('woff'),
		url('../assets/fonts/NeueHaasUnicaW1G-Bold.ttf') format('truetype'),
		url('../assets/fonts/NeueHaasUnicaW1G-Bold.svg#NeueHaasUnicaW1G-Bold')
			format('svg');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'More Gothic';
	src: url('../assets/fonts/MoreGothic-Bold.eot');
	src: url('../assets/fonts/MoreGothic-Bold.eot?#iefix')
			format('embedded-opentype'),
		url('../assets/fonts/MoreGothic-Bold.woff2') format('woff2'),
		url('../assets/fonts/MoreGothic-Bold.woff') format('woff'),
		url('../assets/fonts/MoreGothic-Bold.ttf') format('truetype'),
		url('../assets/fonts/MoreGothic-Bold.svg#MoreGothic-Bold') format('svg');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Trade Gothic LT Pro Cn';
	src: url('../assets/fonts/TradeGothicLTPro-Cn18.eot');
	src: url('../assets/fonts/TradeGothicLTPro-Cn18.eot?#iefix')
			format('embedded-opentype'),
		url('../assets/fonts/TradeGothicLTPro-Cn18.woff2') format('woff2'),
		url('../assets/fonts/TradeGothicLTPro-Cn18.woff') format('woff'),
		url('../assets/fonts/TradeGothicLTPro-Cn18.ttf') format('truetype'),
		url('../assets/fonts/TradeGothicLTPro-Cn18.svg#TradeGothicLTPro-Cn18')
			format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Can-Am Gothic';
	src: url('../assets/fonts/CanAmGothic-Bold.eot');
	src: url('../assets/fonts/CanAmGothic-Bold.eot?#iefix')
			format('embedded-opentype'),
		url('../assets/fonts/CanAmGothic-Bold.woff2') format('woff2'),
		url('../assets/fonts/CanAmGothic-Bold.woff') format('woff'),
		url('../assets/fonts/CanAmGothic-Bold.ttf') format('truetype'),
		url('../assets/fonts/CanAmGothic-Bold.svg#CanAmGothic-Bold')
			format('svg');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Neue Haas Unica W1G';
	src: url('../assets/fonts/NeueHaasUnicaW1G-Regular.eot');
	src: url('../assets/fonts/NeueHaasUnicaW1G-Regular.eot?#iefix')
			format('embedded-opentype'),
		url('../assets/fonts/NeueHaasUnicaW1G-Regular.woff2') format('woff2'),
		url('../assets/fonts/NeueHaasUnicaW1G-Regular.woff') format('woff'),
		url('../assets/fonts/NeueHaasUnicaW1G-Regular.ttf') format('truetype'),
		url('../assets/fonts/NeueHaasUnicaW1G-Regular.svg#NeueHaasUnicaW1G-Regular')
			format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
