@import '../../styles/colours.scss';
@import '../../styles/mixins.scss';

.contactDetails {
	display: flex;
	width: 100%;
}

.noBorderRadius {
	border-radius: 0 !important;
}
