// $primary: #FEDBD0;
// $secondary: #FEEAE6;
// $complimentary: #442C2E;

// $darkGrey: #78746c;
// $brown: #b09471;
// $green: #91a567;
// $lightOrange: #ffdd9f;
// $lightYellow: #f9f4b4;


// Branding
// brp-world
$brp-yellow: #FFD200;
$brp-grey: #8A9199;
$brp-silver: #8A9199;
$brp-black: #333132;
$brp-white: #ffffff;

// sea-doo
$seadoo-black: #000000;
$seadoo-white: #ffffff;

// aqua-to-green
$seadoo-aqua: #2cd5c4;
$seadoo-green: #c5e86c;

// ski-doo
$skidoo-yellow: #ffc72c;
$skidoo-yellow-75: rgba(255, 199, 44,0.75);
$skidoo-yellow-50: rgba(255, 199, 44,0.50);
$skidoo-yellow-25: rgba(255, 199, 44,0.25);
$skidoo-grey: #d9d9d9;

// can-am on-road
$canam-on-red:#e03c31;

// can-am off-road
$canam-off-yellow: #ffc72c;


