@import '../styles/colours.scss';
@import '../styles/fonts.scss';
* {
	margin: 0;
	padding: 0;
}

#root {
	position: relative;
}

button, input, fieldset, textarea, ::placeholder,
p,/* Paragraph Element */
h1, h2, h3, h4, h5, h6,/* Heading Elements */
blockquote, q,/* Quotation Elements */
abbr, cite,/* Abbreviation and Citation Elements */
code, pre,/* Code and Preformatted Text Elements */
em, strong,/* Emphasis and Strong Importance Elements */
mark, ins, del,/* Marked, Inserted, and Deleted Text Elements */
s,/* Strikethrough Element */
sup, sub,/* Superscript and Subscript Elements */
span, div {
	/* Generic Inline and Block Containers */
	font-family: 'Trade Gothic LT Pro Cn' !important; //, serif;
}

// reset all input fields' border radius to 0
.MuiInputBase-root.MuiOutlinedInput-root {
	border-radius: 0 !important;
}

a {
	text-decoration: none;
	color: $brp-black;
}

.grey-color {
	color: $brp-grey;
}
html {
	min-height: 100%;
	display: flex;
	flex-direction: column;
}
body {
	display: flex;
	flex-grow: 1;
}
#root {
	display: flex;
	flex-direction: column;
	flex: 1;
}
main.app {
	flex: 1;
	min-height: 50vh;
}

.capitalise {
	span::first-letter {
		text-transform: capitalize;
	}
}

// map styles

.map-container {
	width: 100%;
	height: 300px;
}

.description {
	img {
		width: 100%;
		margin-bottom: 1em;
	}
	p {
		margin-top: 1em;
	}
	a {
		text-decoration-color: $brp-yellow !important;
		text-decoration: underline;
	}
}

.titleAndTextAndButtonContainer {
	display: flex !important;
}

// Image gallery overrides
.image-gallery-thumbnails-wrapper {
	max-width: 90vw;
}
.image-gallery-icon:hover {
	color: $brp-yellow;
}
.image-gallery-thumbnail:hover {
	outline: none;
	border: 4px solid $brp-black;
}
.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus {
	outline: none;
	border: 4px solid $brp-yellow;
}
