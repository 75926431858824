.filterContainer,
.gridContainer {
	box-sizing: border-box;
}
.filterContainer {
	padding-top: 16px;
	// padding-right: 16px;
}
.gridContainer {
	// padding-left: 16px;
}
.sortContainer {
}
.productContainer {
	// display: flex;
}
